<template>
  <div class="return-order-list__container">
    <!-- 海关异常 -->
    <div
      v-if="isabnormalOrderExist"
      class="alert-con custom-notice-alert"
      DA-view-type="ordinary"
      DA-sa-name="orderlist_abnormalpackage"
      :DA-sa-param="JSON.stringify({ orderid: order.billno })"
      @click.stop="jumpToCustomsInterceptionEvt(order.billno)"
    >
      <swiper-container
        ref="customNoticeSwiper"
        init="false"
        autoplay-delay="5000"
        pagination="true"
        :loop="customAbnormalList.length == 1 ? false : true"
      >
        <swiper-slide
          v-for="(item, noticeIndex) in customAbnormalList"
          :key="noticeIndex"
        >
          <s-alert
            type="warning"
            jumpable
            show-icon
            icon-class="suiiconfont sui_icon_notice_16px"
          >
            {{ item }}
          </s-alert>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { register } from 'swiper/element'
import { Pagination, Autoplay } from 'swiper/modules'

typeof window !== 'undefined' && register()

export default {
  name: 'ReturnNotice',
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    ...mapState('orderList', ['abnormalOrderList', 'abnormalOrderListType', 'publicLocalData']),
    publicCdn () {
      if (typeof window !== 'undefined') {
        return gbCommonInfo.PUBLIC_CDN
      }
      return this.publicLocalData?.PUBLIC_CDN || ''
    },
    isabnormalOrderExist() {
      const { billno = '' } = this.order
      // 旧回参billnoList:	string[],新回参confirmInfoList: object[],{billno,confirmStatus,displayStatus}
      if (this.abnormalOrderListType == 'old') return this.abnormalOrderList.includes(billno)
      let abnormalOrder =
        this.abnormalOrderList.filter(
          item => item?.displayStatus == 1 && [1, 2, 3, 4, 7, 8, 9, 10, 11, 16].includes(item?.confirmStatus)
        ) || []
      let billNoList = abnormalOrder.map(item => item.billno)
      return billNoList.includes(billno)
    },
    customAbnormalList() {
      const { billno = '' } = this.order
      const uniqueStatusSet = new Set()

      let hasReturnAbnormal = false, // 逆向异常
          hasOrderAbnormal = false  // 正向异常

      for (const item of this.abnormalOrderList) {
        if (item?.displayStatus === 1 && item.billno === billno) {
          uniqueStatusSet.add(item.confirmStatus)
          if (!item.unionReturnNo) {
            hasOrderAbnormal = true
          }
          if (item.confirmStatus == 1) {
            // 待处理的退货异常，有退货单号
            if (item.unionReturnNo) {
              hasReturnAbnormal = true
            }
          }
        }
      }

      const uniqueStatusArrayLength = uniqueStatusSet.size

      if (uniqueStatusArrayLength > 0) {
        // 正向文案优先展示
        let result = []
        if (hasOrderAbnormal) {
          result.push(this.language.SHEIN_KEY_PWA_22142)
        }
        if (hasReturnAbnormal) {
          result.push(this.language.SHEIN_KEY_PWA_34583)
        }
        return result
      } else {
        return []
      }
    }
  },
  mounted() {
    this.initCustomSwiper()
  },
  methods: {
    initCustomSwiper() {
      this.$nextTick(() => {
        const swiperEl = this.$refs.customNoticeSwiper
        const params = {
          modules: [Pagination, Autoplay],
          injectStylesUrls: [
            this.publicCdn + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'
          ],
          injectStyles: [`
            .swiper-pagination-bullet {
              width: 4px;
              height: 4px;
              opacity: 1;
              background: #cccccc;
              vertical-align: middle;
              display: inline-block;
              border-radius: 10px;
              margin: 0 2px !important;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullet-active {
              width: 4px;
              height: 4px;
              background: #222;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullets {
              line-height: 1;
              bottom: 4px !important;
            }
          `],
        }

        if (swiperEl) {
          Object.assign(swiperEl, params)
          swiperEl.initialize()
        }
      })
    },
    jumpToCustomsInterceptionEvt: function (billno) {
      const { langPath } = this.publicLocalData
      const onlyReturn = this.customAbnormalList.length == 1 && this.customAbnormalList[0] == this.language.SHEIN_KEY_PWA_34583
      this.$router.push({
        path: `${langPath}/user/orders/customsInterception/${billno}`,
        query: {
          source: 'orderlist',
          customType: onlyReturn ? 2 : 1
        }
      })
    },
  }
}
</script>

<style lang="less">
.custom-notice-alert {
  .S-alert__icon {
    color: #767676;
  }
}
</style>
<style lang="less" scoped>
.return-order-list__container {
  .alert-con {
    margin-top: 0.32rem;
    padding: 0 24/75rem;
    margin-top: 24/75rem;

    /deep/ .S-alert__jumpbtn {
      font-size: 16px;
      margin-left: 8/75rem;
      margin-right: 20/75rem;
      color: #959595;
    }
  }
}
.return-order-list__container {
  .custom-notice-alert {
    .S-alert {
      padding: 24/75rem;
      min-height: 120/75rem;
    }
  }
}
</style>
