<template>
  <div
    ref="listRef"
    class="order-list-item"
    :data-billno="billno"
  >
    <slot 
      :pay-now-label-list="payNowLabelList"
      :repurchase-analysis-data="repurchaseAnalysisData"
      :orderBtnMore="orderBtnMore"
    >
    </slot>
    <!-- 自主标记提示 -->
    <div 
      v-if="showMark"
      ref="markRef"
      class="resume-shipment-notice"
    >
      <span
        v-if="markLeft"
        :style="{ left: `${markLeft}px` }"
        class="resume-shipment-notice__arrow"
      >
      </span>
      <div class="resume-shipment-notice__desc">
        {{ language.SHEIN_KEY_PWA_34359 }}
      </div>
    </div>
    <div
      v-if="showReturnTimeTip"
      ref="returnTimeRef"
      class="return-time"
    >
      <span
        v-if="left"
        :style="{ left: `${left}px` }"
        class="return-time__arrow"
      >
      </span>
      <ReturnTimeTip
        :order-info="order"
        :public-local-data="publicLocalData"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ReturnTimeTip from 'public/src/pages/user/child_pages/orders_bff/common/returnTime/ReturnTimeTip.vue'
export default {
  name: 'OrderListItem',
  components: {
    ReturnTimeTip
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    showMark: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      left: 0,
      markLeft: 0,
      billno: '',
      orderBtnMore: {
        isShowBtnMore: false,
        btnMoreList: []
      }
    }
  },
  computed: {
    ...mapState('orderList', ['language', 'orders', 'abtInfos', 'publicLocalData']),
    // 利诱类型abt实验列表
    testTypeList() {
      const abtTypeInfo = this.abtInfos?.unpaidOrderTestAbt?.OrderlistInducementType
      if (typeof abtTypeInfo === 'string' && abtTypeInfo) {
        return abtTypeInfo.split(',')
      }
      return []
    },
    // 利诱列表
    payNowLabelList() {
      const labelList = []
      const { unpaid_lure_point_info = {} } = this.order || {}
      // 1-订单优惠信息
      if (unpaid_lure_point_info.type == 'save') {
        labelList.push({
          type: 'discount',
          name: 'sui_icon_coupon_discount_14px',
          inducementType: '1',
          text: unpaid_lure_point_info.lure_point_tip
        })
      }
      // 2-免邮信息
      if (unpaid_lure_point_info.type == 'freeShipping') {
        labelList.push({
          type: 'success',
          name: 'sui_icon_free_shipping_14px',
          inducementType: '2',
          text: unpaid_lure_point_info.lure_point_tip
        })
      }
      // 3-免费退信息
      if (unpaid_lure_point_info.type == 'freeReturn') {
        labelList.push({
          type: 'success',
          name: 'sui_icon_free_return_14px',
          inducementType: '3',
          text: unpaid_lure_point_info.lure_point_tip
        })
      }
      // 4-价保信息
      if (unpaid_lure_point_info.type == 'priceGuarantee') {
        labelList.push({
          type: 'success',
          name: 'sui_icon_guarantee_price_green_14px',
          inducementType: '4',
          text: unpaid_lure_point_info.lure_point_tip
        })
      }
      labelList.sort((a, b) => {
        return this.testTypeList.indexOf(a.inducementType) - this.testTypeList.indexOf(b.inducementType) 
      })
      return labelList
    },
    showReturnTimeTip () {
      return this.order?.returnEffect?.desc || ''
    },
    isRepurchaseLabelInMore() {
      const isInclude = this.orderBtnMore?.btnMoreList?.some(item => item?.text.includes(this.language.SHEIN_KEY_PWA_16211))
      return Boolean(isInclude)
    },
    repurchaseAnalysisData() {
      return {
        order_id: this.order.billno, 
        goods_id: this.getGoodsIds(this.order),
        order_status: this.order.orderStatus,
        is_promoshow: !this.isRepurchaseLabelInMore && this.order.repurchase_lure_info?.tip ? 1 : 0
      }
    },
  },
  mounted() {
    // 触发 updated
    this.billno = this.order?.billno || ''
  },
  updated () {
    this.$nextTick(() => {
      this.adjuctOrderListMore()
      this.getArrowPosition()
      this.getMarkrrowPosition()
    })
  },
  methods: {
    getGoodsIds(order) {
      const goods_ids = []
      if (order.orderGoodsList && order.orderGoodsList.length) {
        order.orderGoodsList.forEach(function (obj) {
          goods_ids.push(obj.goods_id)
        })
      }
      return goods_ids.join(',')
    },
    // 判断按钮列表一行是否放得下
    adjuctOrderListMore() {
      const listBtnBillno = $(`#list-btn-${this.billno}`)
      const boxWidth = listBtnBillno?.width()
      const buttonList = listBtnBillno?.find('button.order-list-bot__btn')
      let buttonWidth = 0
      if (boxWidth > 0) {
        let overIndex = 0
        buttonList?.each((i, n) => {
          const obj = $(n)
          const objParent = obj?.parent()
          const objTop = objParent?.hasClass('comment-btn-op') ? objParent : obj
          buttonWidth = buttonWidth + objTop?.width()
          if (buttonWidth + 80 > boxWidth) {
            overIndex = i
          }
        })
        if (overIndex > 0) {
          this.orderBtnMore.isShowBtnMore = true
          buttonList?.each((i, n) => {
            const obj = $(n)
            const objParent = obj?.parent()
            let addClass = ''
            const objTop = objParent?.hasClass('comment-btn-op') ? objParent : obj
            if (obj.hasClass('opacity-item') || obj.hasClass('disabled-address_edit')) {
              addClass = 'opacity-item'
            }
            if (i >= overIndex) {
              this.orderBtnMore.btnMoreList.unshift({
                el: obj,
                text: obj.html(),
                addClass: addClass
              })
              objTop?.remove()
            }
          })
        }
      }
    },
    // 箭头跟随return_item移动
    getArrowPosition() {
      if (!this.showReturnTimeTip) {
        return
      }
      const containerDom = this.$refs.returnTimeRef
      let buttonDom

      const inMoreBtns = this.orderBtnMore?.btnMoreList?.some(item => item?.text.includes(this.language.SHEIN_KEY_PWA_33481))
      if (inMoreBtns) {
        buttonDom = this.$refs.listRef.querySelector('.my-reviewed-comment')
      } else [
        buttonDom = this.$refs.listRef.querySelector('.j-return-btn')
      ]

      if (containerDom && buttonDom) {
        const containerRect = containerDom.getBoundingClientRect()
        const accountRect = buttonDom.getBoundingClientRect()
        const leftPosition = accountRect.left + accountRect.width / 2 - containerRect.left
        this.left = leftPosition
      } else {
        this.left = 0
      }
    },
    getMarkrrowPosition() {
      if (!this.showMark) return
      const inMoreBtns = this.orderBtnMore?.btnMoreList?.some(item => item?.text.includes(this.language.SHEIN_KEY_PWA_34358))
      const cssSelector = inMoreBtns ? '.my-reviewed-comment' : '.resume-shipment-btn'
      const buttonDom = this.$refs.listRef.querySelector(cssSelector)
      if (this.$refs.markRef && buttonDom) {
        const btnRect = buttonDom.getBoundingClientRect()
        const leftPosition = btnRect.left + btnRect.width / 2 
        this.markLeft = leftPosition
      } else {
        this.markLeft = 0
      }
    }
  }
}
</script>
<style lang="less" scoped>
.return-time {
  position: relative;
  &__arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 6px solid #f6f6f6;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.resume-shipment-notice {
  position: relative;
  direction: ltr;
  padding: 0 24/75rem 16/75rem;
  text-align: left;
  font-size: 24/75rem;
  font-weight: 400;
  line-height: normal;
  word-break: break-word;
  color: @sui_color_gray_dark1;

  &__desc {
    padding: 16/75rem;
    background: @sui_color_gray_weak2;
  }

  &__arrow {
    position: absolute;
    top: 0;
    transform: translateY(-50%) rotate(45deg);
    width: 16/75rem;
    height: 16/75rem;
    background: @sui_color_gray_weak2;
  }
}
</style>
