<template>
  <div class="order-list-new__container">
    <div @click="navToDetail($event, order)">
      <div
        class="list-title list-title_new"
        :class="{ 'fsp-element': index < 3 }"
      >
        <em>
          <i :class="'order-status-' + ordersStatus.class[order.orderStatus]"></i>
          <i class="status_text">
            <span class="order-status-text">{{ order.orderStatusTitle || ordersStatus.text[order.orderStatus] }}</span>
          </i>
          <i
            v-if="!!order.orderStatusTitleTip"
            class="order-title-tips iconfont icon-pro"
            @click.stop="openTips(order.orderStatusTitleTip)"
          >
          </i>
        </em>
        <!-- 普通提示 -->
        <span
          v-if="orderStatusTips.title"
          class="order-title-tips"
          @click.stop="openTips(orderStatusTips.tips)"
        >
          {{ orderStatusTips.title }}
        </span>
        <!-- 功能提示 -->
        <span
          v-if="showCombinedTips"
          v-tap="order.combined_shipped_flag == 1 ? { id: '1-20-1-32' } : {}"
          v-expose="order.combined_shipped_flag == 1 ? { id: '1-20-1-30', data: { billno: order.billno } } : {}"
          class="order-title-tips"
          @click.stop="showCombinedTipsEvt(order)"
        >
          <template v-if="order.combined_shipped_flag == 1">{{ language.SHEIN_KEY_PWA_14586 }}</template>
          <template v-else-if="order.show_mutil_package == 1">{{ language.SHEIN_KEY_PWA_16963 }}</template>
          <template v-else-if="order.combined_return_msg">{{ language.SHEIN_KEY_PWA_23067 }}</template>
          <i
            class="iconfont icon-pro"
          >
          </i>
        </span>
        <s-label
          v-if="!!order.ocb_order_tip"
          class="one_click_pay-tag"
          type="success"
        >
          {{ order.ocb_order_tip }}
        </s-label>
        <s-label
          v-if="!!order.localWarehouseTip"
          class="one_click_pay-tag"
          type="success"
        >
          {{ order.localWarehouseTip }}
        </s-label>
      </div>
      <div
        class="list-title list-item-info"
        :class="{ 'fsp-element': index < 3 }"
      >
        <span class="order-title">
          {{ language.SHEIN_KEY_PWA_16822 }}
          <em
            :class="`order-number__${order.billno}`"
            @click.stop
          >
            {{ order.billno }}
          </em>
        </span>
        <s-label
          v-if="order.marketing_type == 1"
          class="free-trial-tag"
          type="promo"
        >
          {{ freeTrialTagText }}
        </s-label>
        <s-label
          v-if="order.isCutKnifeOrder == 1"
          class="free-gift_tag"
          type="promo"
        >
          {{ language.SHEIN_KEY_PWA_23666 }}
        </s-label>
      </div>
      <!-- 推荐地址入口 -->
      <s-alert
        v-if="customerSyncTip"
        type="info"
        show-icon
        icon-class="suiiconfont sui_icon_location_15px"
        jumpable
        class="address-recommend"
        @click.stop.native="controlDialogShow"
      >
        {{ language.SHEIN_KEY_PWA_25863 }}
      </s-alert>
      <!-- cod审单 -->
      <div
        v-if="!!isShowCODCheckNotice"
        class="alert-con cod-check-notice"
      >
        <s-alert
          v-expose="{ id: '1-20-1-69', data: { order_no: order.billno } }" 
          v-tap="{ id: '1-20-1-70', data: { order_no: order.billno } }"
          type="warning" 
          jumpable
          show-icon
          icon-class="suiiconfont sui_icon_news_24px"
          @click.stop.native="openCodRiskOrderEvt"
        >
          {{ isShowCODCheckNotice }}
        </s-alert>

        <!-- COD风控审单弹框 -->
        <ClientOnly>
          <CodRiskOrderPop
            :show="showCodRiskOrderPop"
            :language="language"
            :codRiskOrderTitle="codRiskOrderTitle"
            :codRiskOrderText="codRiskOrderText"
            :curOrder="order"
            :page-from="'order_list'"
            @closeCodRiskOrderPop="closeCodRiskOrderEvt"
          />
        </ClientOnly>
      </div>
      <!-- 履约异常文案 -->
      <div
        v-else-if="!!order.orderFulfillmentTips"
        class="alert-con"
      >
        <s-alert
          type="warning"
          show-icon
          icon-class="suiiconfont sui_icon-reported_20px"
        >
          {{ order.orderFulfillmentTips }}
        </s-alert>
      </div>
      <!-- 海关拦截绿色通道 -->
      <div
        v-else-if="customAbnormalList.length"
        v-expose="{ id: '1-20-1-154', data: { orderid: order.billno } }" 
        class="alert-con custom-notice-alert"
      >
        <swiper-container
          ref="customNoticeSwiper"
          init="false"
          autoplay-delay="5000"
          pagination="true"
          :loop="customAbnormalList.length == 1 ? false : true"
        >
          <swiper-slide
            v-for="(item, noticeIndex) in customAbnormalList"
            :key="noticeIndex"
          >
            <s-alert
              type="warning"
              jumpable
              show-icon
              icon-class="suiiconfont sui_icon_notice_16px"
              @click.stop.native="jumpToCustomsInterceptionEvt(item.customs_package_link)"
            >
              {{ item.customs_tip }}
            </s-alert>
          </swiper-slide>
        </swiper-container>
      </div>
      <!-- CSP异常公告 -->
      <div
        v-else-if="isShowCspNotice"
        id="list-csp-notice"
        v-expose="{
          id: '1-20-1-65',
          data: { billno: order.billno, num: cspNoticeContent.length }
        }"
      >
        <swiper-container
          init="false"
          autoplay-delay="5000"
          pagination="true"
          :loop="cspNoticeContent.length == 1 ? false : true"
          :class="['csp-notice-container', `csp-notice-${order.billno}`]"
        >
          <swiper-slide
            v-for="(item, noticeIndex) in cspNoticeContent"
            :key="noticeIndex"
          >
            <div
              class="list-csp csp-notice-slide"
            >
              <div class="tip-container">
                <div class="tip-content">
                  <i class="suiiconfont sui_icon_notice_16px notice-icon"></i>
                  <span class="tip-text">
                    {{ item }}
                  </span>
                </div>
              </div>
              <i
                v-tap="{
                  id: '1-20-1-66',
                  data: { billno: order.billno, num: cspNoticeContent.length, type: 'pop_up' }
                }"
                class="suiiconfont sui_icon_more_right_16px more-icon"
                @click.stop="openNoticeModal"
              >
              </i>
            </div>
          </swiper-slide>
          <ClientOnly>
            <LazyMount mount-prop-name="show">
              <CspNoticeCommon
                :show="isShowCspDrawer"
                :page-from="'order_list'"
                :billno="order.billno"
                :language="language"
                :csp-notice-list="cspNoticeListNew"
                :package-goods-list="packageGoodsList"
                :da-click-id="'1-20-1-67'"
                :da-expose-id="'1-20-1-68'"
                @close="isShowCspDrawer = false"
              />
            </LazyMount>
          </ClientOnly>
        </swiper-container>
      </div>
      <!-- 风控审单 -->
      <div 
        v-else-if="!!sensitiveInfo.statusTip"
        class="alert-con"
      >
        <s-alert
          v-expose="{
            id: '1-20-1-177',
            data: { status: sensitiveInfo.sensitive_status == 'no_submit' ? 0 : 1 }
          }"
          class="order-notice-tip"
          type="warning"
          :show-icon="true"
          jumpable
          @click.stop.native="toVerification"
        >
          {{ sensitiveInfo.statusTip }}
        </s-alert>
      </div>
      <!-- cod拒绝原因 -->
      <div
        v-else-if="!!rejectQuestionnaire.tip"
        v-expose="{ id: '1-20-1-37' }"
        class="alert-con"
      >
        <s-alert
          type="warning"
          jumpable
          :show-icon="order.isFilledReason != 1"
          icon-class="suiiconfont sui_icon-reported_20px"
          :class="{'view-rejection-reason': order.isFilledReason == 1}"
          @click.stop.native="toRejectReason"
        >
          {{ rejectQuestionnaire.tip }}
        </s-alert>
      </div>
      <!-- 巴西平台化迁移 -->
      <platFormItem
        :class="{ 'fsp-element': index < 3 }"
        :order="order"
        :language="language"
      />
    </div>
    <order-list-track
      :order="order"
      :language="language"
    />
    <orderListApplication
      v-if="order.reverse_track_info"
      :order="order"
      :language="language"
    />
    <div
      class="text-capitalize list-bot-price-item-total"
      :class="{ 'fsp-element': index < 3 }"
    >
      <span class="list-bot-price-item">
        {{ order.orderGoodsSum }}
        <template v-if="order.orderGoodsSum == 1">
          {{ language.SHEIN_KEY_PWA_14952 }}
        </template>
        <template v-else>
          {{ language.SHEIN_KEY_PWA_15456 }}
        </template>
      </span>
      <span class="list-bot-price-total">
        {{ language.SHEIN_KEY_PWA_24019 }}:
        <span 
          class="price"
          :class="{
            'promotion-two': showRedPrice
          }"
        >
          {{ order.totalPrice || '' }}
        </span>
      </span>
      <pay-now-tag
        v-if="showPromotionTestTwo"
        :language="language"
        :pay-now-label-list="payNowLabelList"
      />
    </div>
    <ClientOnly>
      <LazyMount>
        <s-dialog
          append-to-body
          :visible.sync="showOverB2B2CCondDialog"
        >
          <template slot="title">
            <div
              class="cond-text"
              v-html="showOverB2B2CCondText"
            >
            </div>
          </template>
          <template slot="footer">
            <s-button
              :type="['primary']"
              :width="'100%'"
              @click="showOverB2B2CCondDialog = false"
            >
              {{ language.SHEIN_KEY_PWA_15312 }}
            </s-button>
          </template>
        </s-dialog>
      </LazyMount>
      <!-- TODO 推荐地址，涉及地址，暂时保留, 待地址融合规划 -->
      <CustomerSyncToUserAddress
        v-if="controlDialog"
        :controlDialog="controlDialog"
        :orderData="order"
        :language="language"
        @controlDialogClose="controlDialogClose"
        @recomAddressClose="recomAddressClose"
      />
    </ClientOnly>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import platFormItem from './platFormItem.vue'
import OrderListTrack from './OrderListTrack.vue'
import orderListApplication from './orderListApplication.vue'
import PayNowTag from './components/PayNowTag.vue'
import { tipList } from '../common/tipConfig'
import { Label, LazyMount } from '@shein/sui-mobile'
import schttp from 'public/src/services/schttp'
import CspNoticeCommon from 'public/src/pages/common/orderLogic/cspNoticeCommon.vue'
import CodRiskOrderPop from 'public/src/pages/user/child_pages/orders_bff/common/codRiskOrderPop.vue'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import CustomerSyncToUserAddress from 'public/src/pages/components/customerSyncToUserAddress/index.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { register } from 'swiper/element'
import { Pagination, Autoplay } from 'swiper/modules'
import ClientOnly from 'vue-client-only'
import { htmlDecode } from '@shein/common-function'
import { markPoint } from 'public/src/services/mark/index.js'
import { combineStatusSer, confirmDeliveryTipsSer, queryCurrentOrderInfoSer } from './service'
import { prefetchOrderDetailPageData } from 'public/src/pages/user/child_pages/orders/common/utils.js'

;[Label].forEach(c => Vue.use(c))

typeof window !== 'undefined' && register()
daEventCenter.addSubscriber({ modulecode: '2-29' })

const { langPath } = gbCommonInfo
export default {
  name: 'ListItem',
  directives: { tap, expose },
  components: {
    platFormItem,
    OrderListTrack,
    CspNoticeCommon,
    CustomerSyncToUserAddress,
    CodRiskOrderPop,
    orderListApplication,
    PayNowTag,
    LazyMount,
    ClientOnly,
  },
  props: {
    order: {
      type: Object,
      default: function () {
        return {}
      }
    },
    language: {
      type: Object,
      default: function () {
        return {}
      }
    },
    fromPage: {
      type: String,
      default: 'order_list'
    },
    payNowLabelList: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      controlDialog: false,
      showRecomAddress: true,
      showOverB2B2CCondText: '',
      showOverB2B2CCondDialog: false,
      isShowCspDrawer: false,
      cspNoticeListNew: {},
      packageGoodsList: [],
      touchDiff: 0,
      swiperInstance: null,
      showCodRiskOrderPop: false,
      codRiskOrderTitle: '',
      codRiskOrderText: ''
    }
  },
  computed: {
    ...mapState('orderList', ['publicLocalData', 'isSsr', 'status_type', 'abnormalOrderList', 'abnormalOrderListType', 'specialOutgoingOrderList', 'abtInfos']),
    ...mapGetters('orderList', ['ordersStatus']),
    publicCdn () {
      if (typeof window !== 'undefined') {
        return gbCommonInfo.PUBLIC_CDN
      }
      return this.publicLocalData?.PUBLIC_CDN || ''
    },
    orderStatusTips () {
      return this.order?.orderStatusTips || {}
    },
    freeTrialTagText () {
      return this.language?.SHEIN_KEY_PWA_16212?.toLowerCase() || ''
    },
    customerSyncTip () {
      let flag = !!this.order?.addressSyncInfo?.customerSyncTip && this.showRecomAddress
      if (flag) {
        daEventCenter.triggerNotice({
          daId: '2-29-1',
          extraData: {
            name: 'expose_customer_sync_to_address'
          }
        })
      }
      return flag
    },
    showCombinedTips () {
      let { orderStatus, combined_shipped_flag, show_mutil_package, combined_return_msg, confirmDeliveryBonusPoints } = this.order || {}
      return combined_shipped_flag == 1 || show_mutil_package == 1 || !!combined_return_msg || (orderStatus == 5 && confirmDeliveryBonusPoints)
    },
    sensitiveInfo () {
      return this.order?.sensitiveInfo || {}
    },
    rejectQuestionnaire () {
      return this.order?.reject_questionnaire || {}
    },
    showPromotionTestTwo() {
      if (!this.abtInfos?.unpaidOrderTestAbt || !Array.isArray(this.payNowLabelList)) return false
      return this.abtInfos?.unpaidOrderTestAbt?.OrderlistPromotionTest == 'on2' && this.payNowLabelList.length
    },
    showRedPrice() {
      return this.showPromotionTestTwo && this.payNowLabelList?.[0]?.inducementType == '1'
    },
    cspNoticeContent() {
      return this.order?.csp_abnormal_notices?.slice?.(0, 3) || []
    },
    isShowCspNotice() {
      return !!this.cspNoticeContent.length
    },
    isShowCODCheckNotice() {
      return this.order?.codAuditNotice || ''
    },
    customAbnormalList() {
      return this.order?.customs_info_list || []
    }
  },
  watch: {
    isShowCspNotice: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.initSwiper()
        }
      }
    }
  },
  mounted() {
    this.initCustomSwiper()
    this.$nextTick(() => {
      this.initCspSwiper()
    })
    this.initDocumentCopyEvt()
  },
  beforeDestroy () {
    let { billno = '' } = this.order || {}
    let domElement = document?.querySelector(`.order-number__${billno}`)
    domElement?.removeEventListener('copy', this.copyOrderNumberEvt)
  },
  methods: {
    htmlDecode,
    initDocumentCopyEvt () {
      let { billno = '' } = this.order || {}
      let domElement = document?.querySelector(`.order-number__${billno}`)
      domElement?.addEventListener('copy', this.copyOrderNumberEvt)
    },
    copyOrderNumberEvt () {
      daEventCenter.triggerNotice({
        daId: '1-20-1-175',
      })
    },
    /** 跳转风
    /** 跳转风控页面 */
    toVerification() {
      daEventCenter.triggerNotice({
        daId: '1-20-1-176',
        extraData: {
          status: this.sensitiveInfo?.sensitive_status == 'no_submit' ? '0' : '1'
        }
      })
      this.$router.push(`${ langPath }/user/verification?billNo=${ this.order.billno }&sceneType=${this.sensitiveInfo?.scene_type}&pageFrom=orderList`)
    },
    /** 地址推荐入口关闭 */
    recomAddressClose() {
      this.showRecomAddress = false
      this.controlDialog = false
    },
    /** 地址推荐弹窗展示 */
    controlDialogShow() {
      this.controlDialog = true
      daEventCenter.triggerNotice({
        daId: '2-29-1',
        extraData: {
          name: 'click_customer_sync_to_address'
        }
      })
    },
    /** 地址推荐弹窗关闭 */
    controlDialogClose() {
      this.controlDialog = false
    },
    isabnormalOrderExist(billno) {
      // 旧回参billnoList:	string[],新回参confirmInfoList: object[],{billno,confirmStatus,displayStatus}
      if (this.abnormalOrderListType == 'old') return this.abnormalOrderList.includes(billno)
      let abnormalOrder =
        this.abnormalOrderList.filter(
          item => item?.displayStatus == 1 && [1, 2, 3, 4, 7, 8, 9, 10, 11].includes(item?.confirmStatus)
        ) || []
      let billNoList = abnormalOrder.map(item => item.billno)
      return billNoList.includes(billno)
    },
    isSpecialOutgoingOrderEvt(billno) {
      let billNoList = this.specialOutgoingOrderList.map(item => item.billNo)
      return billNoList.includes(billno)
    },
    // 履约异常文案
    specialOutgoingOrderText(billno) {
      let { orderType } = this.specialOutgoingOrderList.find(item => item.billNo == billno) || {}
      let languageKey = tipList[orderType] || tipList.default
      return this.language[languageKey]
    },
    toRejectReason() {
      daEventCenter.triggerNotice({
        daId: '1-20-1-38'
      })
      this.$router.push(`${langPath}/user/orders/rejectReason/${this.order.billno}`)
    },
    navToDetail(e, order) {
      let { billno, order_type, isOldOrder, isReadOnly } = order || {}

      if (isReadOnly == 1) return
      if ($(e.target).hasClass('j-order-btn') || $(e.target).hasClass('j-return-btn')) return
      markPoint('toNextPageClick', 'public')
      daEventCenter.triggerNotice({
        daId: '1-20-1-152',
        extraData: {
          order_id: billno,
          status_type: this.status_type
        }
      })
      
      window.orderDetailPrefetch = prefetchOrderDetailPageData({ billno, orderType: isOldOrder ? 'oldorder' : '' })
      if (isOldOrder) {
        this.$router.push({
          path: `${langPath}/user/orders/detail/${billno}?orderType=oldorder${
            this.fromPage == 'deleted_order_list' ? '&fromRecycle=recycleOrderList' : ''
          }`
        })
      } else {
        // 超省卡单独购及随单购已支付拆单|者付费会员
        this.$router.push({
          path: `${langPath}/user/orders/${
            [5, 6]?.includes(+order_type) ? 'virtualDetail' : 'detail'
          }/${billno}${
            this.fromPage == 'deleted_order_list' ? '?fromRecycle=recycleOrderList' : ''
          }`
        })
      }
    },
    openTips (text) {
      this.showOverB2B2CCondText = text || ''
      this.showOverB2B2CCondDialog = true
    },
    async showCombinedTipsEvt (order = {}) {
      let { billno, merge_buy_billno, orderStatus, combined_shipped_flag, show_mutil_package, combined_return_msg, confirmDeliveryBonusPoints } = order || {}
      if (combined_shipped_flag == 1) {
        let resInfo = await combineStatusSer({ billno })
        this.$emit('showCombinedStatus', {
          billno,
          merge_buy_billno,
          status: resInfo?.info?.is_mixture_shipping == 1
        })
        return 
      }
      if (orderStatus == 5 && confirmDeliveryBonusPoints > 0) {
        let resInfo = await confirmDeliveryTipsSer(billno)
        let { confirmDeliverySucTip, confirmSuccessTip } = resInfo?.info || {}
        this.showOverB2B2CCondText = confirmDeliverySucTip || confirmSuccessTip || ''
        this.showOverB2B2CCondDialog = true
        return 
      }
      if (show_mutil_package == 1) {
        this.showOverB2B2CCondText = this.language?.SHEIN_KEY_PWA_16964 || ''
        this.showOverB2B2CCondDialog = true
        return 
      }
      if (!!combined_return_msg) {
        this.showOverB2B2CCondText = combined_return_msg || ''
        this.showOverB2B2CCondDialog = true
        return 
      }
    },
    jumpToCustomsInterceptionEvt (customLink) {
      daEventCenter.triggerNotice({
        daId: '1-20-1-153',
        extraData: {
          orderid: this.order.billno || ''
        }
      })
      const urlObj = new URL(customLink)
      const query = urlObj.search

      this.$router.push({
        path: `${langPath}/user/orders/customsInterception/${this.order.billno}${query}`,
      })
    },
    async openNoticeModal() {
      this.isShowCspDrawer = true
      const conditionList = [
        {
          is_history_notice: [1]
        },
        {
          is_history_notice: [0],
          notice_status_list: [1]
        }
      ]
      this.isShowCspDrawer = true
      if (!Object.keys(this.cspNoticeListNew).length || !this.packageGoodsList.length) {
        const [noticeList] = await Promise.all([
          this.fetchAbnormalNotice(conditionList),
          this.fetchTrack()
        ])
        this.cspNoticeListNew = noticeList
      }
    },
    initSwiper() {
      this.$nextTick(() => {
        const swiperEl = document.querySelector(`.csp-notice-${this.order.billno}`)
        const params = {
          modules: [Pagination, Autoplay],
          injectStylesUrls: [
            this.publicCdn + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'
          ],
          injectStyles: [`
            .swiper-pagination-bullet {
              width: 6px;
              height: 6px;
              opacity: 1;
              background: #cccccc;
              vertical-align: middle;
              display: inline-block;
              border-radius: 6px;
              margin: 0 2px !important;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullet-active {
              width: 6px;
              height: 6px;
              background: #222;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullets {
              line-height: 1;
              bottom: 5px !important;
            }
          `],
          on: {
            touchEnd: (swiper) => {
              if (swiper?.touches?.diff != this.touchDiff) {
                daEventCenter.triggerNotice({
                  daId: '1-20-1-66',
                  extraData: {
                    billno: this.order.billno,
                    num: this.cspNoticeContent.length,
                    type: 'roll'
                  }
                })
              }
              this.touchDiff = swiper?.touches?.diff
            }
          }
        }

        if (swiperEl) {
          Object.assign(swiperEl, params)
          swiperEl.initialize()
        }
      })
    },
    async fetchAbnormalNotice(conditionList = [{ notice_status_list: [1] }]) {
      let data = await schttp({
        url: '/api/orders/track/abnormalNotice/query',
        method: 'POST',
        data: {
          bill_no_list: [this.order.billno],
          page_code_list: ['track', 'order_list', 'order_detail'],
          condition_list: conditionList
        }
      })
      if (data.code == '0') {
        return data?.info?.notice_list[0]
      }
    },
    async fetchTrack() {
      let data = await schttp({
        url: '/api/orders/track/orderTrack/get',
        method: 'GET',
        params: {
          billno: this.order.billno
        }
      })
      if (data?.orderInfo) {
        // 剔除包裹号为空的
        this.packageGoodsList = data.orderInfo?.packageGoodsList || []
      } else {
        return
      }
    },
    async openCodRiskOrderEvt() {
      let resInfo = await queryCurrentOrderInfoSer({ billNo: this.order?.billno, riskClass: this.order?.riskClass || '' })
      if (resInfo?.code == '0' && !!resInfo?.info?.risk_desc) {
        this.codRiskOrderTitle = resInfo?.info?.popup_title || ''
        this.codRiskOrderText = resInfo?.info?.risk_desc || ''
        this.showCodRiskOrderPop = true
  
        daEventCenter.triggerNotice({
          daId: '1-20-1-71',
          extraData: {
            order_no: this.order?.billno || ''
          }
        })
      } else {
        this.$toast(this.language?.SHEIN_KEY_PWA_25888)
      }
    },
    closeCodRiskOrderEvt() {
      this.showCodRiskOrderPop = false
    },
    initCustomSwiper() {
      this.$nextTick(() => {
        const swiperEl = this.$refs.customNoticeSwiper
        const params = {
          modules: [Pagination, Autoplay],
          injectStylesUrls: [
            this.publicCdn + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'
          ],
          injectStyles: [`
            .swiper-pagination-bullet {
              width: 4px;
              height: 4px;
              opacity: 1;
              background: #cccccc;
              vertical-align: middle;
              display: inline-block;
              border-radius: 10px;
              margin: 0 2px !important;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullet-active {
              width: 4px;
              height: 4px;
              background: #222;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullets {
              line-height: 1;
              bottom: 4px !important;
            }
          `],
        }

        if (swiperEl) {
          Object.assign(swiperEl, params)
          swiperEl.initialize()
        }
      })
    },
    initCspSwiper() {
      const swiperEl = document.querySelector(`.csp-notice-${this.order.billno}`)
      if (!swiperEl) {
        return
      }
      const params = {
        modules: [Pagination, Autoplay],
        injectStylesUrls: [
          this.publicCdn + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'
        ],
        pagination: {
          clickable: true
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        injectStyles: [`
          .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
            opacity: 1;
            background: #cccccc;
            vertical-align: middle;
            display: inline-block;
            border-radius: 6px;
            margin: 0 2px !important;
            border: none;
          }
        `,
        `
          .swiper-pagination-bullet-active {
            width: 6px;
            height: 6px;
            background: #222;
            border: none;
          }
        `,
        `
          .swiper-pagination-bullets {
            line-height: 1;
            bottom: 5px !important;
          }
        `],
        on: {
          touchEnd: (swiper) => {
            if (swiper?.touches?.diff != this.touchDiff) {
              daEventCenter.triggerNotice({
                daId: '1-20-1-66',
                extraData: {
                  billno: this.order.billno,
                  num: this.cspNoticeContent.length,
                  type: 'roll'
                }
              })
            }
            this.touchDiff = swiper?.touches?.diff
          }
        }
      }

      Object.assign(swiperEl, params)
      swiperEl.initialize()
    },
  }
}
</script>
<style lang="less">
.custom-notice-alert {
  .S-alert__icon {
    color: #767676;
  }
}
</style>
<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.order-list-new__container {
  .list-title_new{
    display: flex;
    align-items: center;
  }
  .order-title-tips{
    .margin-l(.1rem);
    .padding-l(0);
    color: #666;
    font-size: 24/75rem;
  }
  .address-recommend {
    margin: 0.32rem 0.32rem 0 0.32rem;
  }
  .alert-con {
    margin-top: 0.32rem;
    padding: 0 24/75rem;
    margin-top: 24/75rem;
    /deep/ [class*='sui_icon-reported'] {
      .font-dpr(28px);
      color: #bf4123;
    }

    /deep/ .S-alert {
      padding: 20/75rem 0 20/75rem 20/75rem;
    }

    /deep/ .S-alert__jumpbtn {
      font-size: 16px;
      margin-left: 8/75rem;
      margin-right: 20/75rem;
      color: #959595;
    }
    &.cod-check-notice {
      margin: 0.32rem 0.32rem 0 0.32rem;
      /deep/ .S-alert__icon {
        .font-dpr(28px);
      }
    }
  }

  .order-title {
    display: inline-flex;
    color: @sui_color_gray_dark2;
    em {
      .margin-l(.1rem);
    }
  }
  .one_click_pay-tag {
    color: #198055;
    background: #f5fcfb;
    padding: 0 4px;
    .margin-l(.1rem);
  }

  /deep/ .view-rejection-reason {
    background-color: @sui_color_white;
    border: 1px solid @sui_color_gray_weak1;

    .S-alert__description {
      color: @sui_color_gray_dark3;
    }
  }
}
#list-csp-notice {
  height: 112/75rem;
  background: @sui_color_prompt_warning;
  margin: 0.32rem 0.32rem 0 .32rem;
  display: block;
  overflow: hidden;
}
.tip-container {
  .tip-content {
    display: flex;
    align-items: center;
  }
  .notice-icon {
    margin-right: 16/75rem;
    color: #767676;
  }
  .tip-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.list-csp {
  font-size: 24/75rem;
  padding: 24/75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .more-icon {
    color: #959595;
    margin-left: 24/75rem;
  }
}
.cond-text {
  word-break: break-word;
}
.order-status-text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
}
.order-list-new__container {
  .custom-notice-alert {
    .S-alert {
      padding: 24/75rem;
      min-height: 120/75rem;
    }
  }
}
</style>
